
    import mixins from 'vue-typed-mixins';
    import VueConstants from '@/components/VueConstants';
    import UserProfile from '@/components/user/UserProfile.vue';

    export default mixins(VueConstants).extend({
        name: 'UserProspectContainer',

        components: {
            'user-profile': UserProfile,
        },

        data() {
            return {};
        },
        props: {
            gettingProspectsForAnotherUser: {
                type: Boolean,
                required: true,
            },
            forceMatch: {
                type: Function,
                required: true,
            },
            gotoPreviousUser: {
                type: Function,
                required: true,
            },
            allowUndo: {
                type: Boolean,
                required: true,
            },
            isFirst: {
                type: Function,
                required: true,
            },
            isLast: {
                type: Function,
                required: true,
            },
            showPrevious: {
                type: Function,
                required: true,
            },
            showNext: {
                type: Function,
                required: true,
            },
            userIdToDisplay: {
                type: Number,
                required: true,
            },
            likeUser: {
                type: Function,
                required: false,
            },
            unlikeUser: {
                type: Function,
                required: false,
            },
            redoLikeUser: {
                type: Function,
                required: false,
            },
            redoUnlikeUser: {
                type: Function,
                required: false,
            },
        },

        methods: {
            likeAction() {
                if (this.likeUser) {
                    this.likeUser();
                } else {
                    if (this.redoLikeUser) {
                        this.redoLikeUser();
                    }
                }
            },
            unlikeAction() {
                if (this.unlikeUser) {
                    this.unlikeUser();
                } else {
                    if (this.redoUnlikeUser) {
                        this.redoUnlikeUser();
                    }
                }
            },
        },
    });
