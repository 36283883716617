
    import Vue, { PropOptions } from 'vue';
    import mixins from 'vue-typed-mixins';
    import { logInvalidParams } from '@/tsfiles/errorlog';
    import VueConstants from '@/components/VueConstants';
    import { Utils } from '@/tsfiles/utils';
    import { DateTime as LuxonDateTime } from 'luxon';
    import Avatar from '@/components/Avatar.vue';
    import { PublicUser, Post } from '@bostonventurestudio/lola-api';

    export default mixins(VueConstants).extend({
        name: 'Chat',

        components: {
            'url-avatar': Avatar,
        },

        props: {
            item: {
                type: Object,
                required: true,
            } as PropOptions<Post>,

            author: {
                type: Object,
                required: true,
            } as PropOptions<PublicUser>,

            showReceipt: {
                type: Boolean,
                required: false,
                default: false,
            },
        },

        data() {
            return {
                json: undefined as any | undefined,
            };
        },

        watch: {
            item: {
                immediate: true,
                handler(newVal, oldVal) {
                    if (newVal && newVal.message) {
                        this.json = JSON.parse(this.item.message as string);
                    } else {
                        this.json = undefined as any | undefined;
                    }
                },
            },
        },

        computed: {
            isMe(): boolean {
                if (!this.item || !this.item.authorUserId) {
                    logInvalidParams(this.$options.name, 'isMe');
                    return false;
                }

                return this.$store.getters.isAuthedUserId(this.item.authorUserId);
            },

            chatTime(): string {
                if (!this.item.updated) {
                    return '';
                }

                return LuxonDateTime.fromISO(this.item.updated).toLocaleString(LuxonDateTime.TIME_SIMPLE);
            },

            getChatMessage(): string {
                if (!this.json) {
                    return '';
                }

                if (this.json.cv) {
                    if (this.isMe && this.$store.getters.isAuthedUserId(this.author.userId)) {
                        return 'Contact info shared...';
                    }

                    return this.author.firstName + ' shared their contact info...';
                }

                // Normal chat messages (e.g., {"message": "this is a message"})
                let ret = this.json.message;
                if (this.json.date && this.json.message.includes('{{DATE_EXPAND}}')) {
                    const localDate = LuxonDateTime.fromISO(this.json.date).toLocaleString({
                        weekday: 'short',
                        year: 'numeric',
                        month: 'short',
                        day: 'numeric',
                        hour: 'numeric',
                        minute: '2-digit',
                        timeZoneName: 'short',
                    });

                    ret = ret.replace('{{DATE_EXPAND}}', localDate);
                }

                return ret;
            },

            serverMessage(): boolean {
                return this.json && this.json.serverGenerated;
            },
        },

        methods: {
            getServerMessage(): string {
                if (!this.json) {
                    return '';
                }

                return this.json.message;
            },
        },
    });
