
    import Vue, { PropOptions } from 'vue';
    import mixins from 'vue-typed-mixins';
    import VueConstants from '@/components/VueConstants';
    import { Utils } from '@/tsfiles/utils';
    import { ApiUtils } from '@/tsfiles/apiutils';
    import { logInvalidParams, logInvalidResponse } from '@/tsfiles/errorlog';
    import UserPriority from '@/components/admin/UserPriority.vue';

    import { SharedConstants, ProspectPreview, StringArrayVisibility } from '@bostonventurestudio/lola-api';
    import { AdminserviceUpdateUserPriority } from '@bostonventurestudio/lola-api/dist/models/AdminserviceUpdateUserPriority';

    export default mixins(VueConstants).extend({
        name: 'UserSearchResults',
        components: {
            'user-priority': UserPriority,
        },
        props: {
            prospects: {
                type: Array,
                required: true,
            } as PropOptions<ProspectPreview[]>,
            pagination: {
                type: Object,
                required: false,
            },
        },
        data() {
            return {
                totalRecords: 0,
                currentPage: 1,
                perPage: SharedConstants.DATING_POOL_PROSPECTS_PAGE_SIZE,
            };
        },
        methods: {
            // Navigate to the user's profile
            goToProfile(userId: number | undefined) {
                if (userId) {
                    window.open('/' + String(userId), '_blank');
                }
            },
            changePage(page: number) {
                this.$emit('page-change', page);
            },
            hasList(prospect: ProspectPreview, propertyName: string): boolean {
                if (!prospect) {
                    logInvalidParams(this.$options.name, 'hasList');
                    return false;
                }

                const prop = prospect[propertyName as keyof ProspectPreview] as StringArrayVisibility;
                if (prop) {
                    const currentList = prop.list;
                    return currentList !== undefined && currentList.length > 0;
                }

                return false;
            },
            getFirstItemInList(prospect: ProspectPreview, propertyName: string): string {
                if (!prospect) {
                    logInvalidParams(this.$options.name, 'getFirstItemInList');
                    return '';
                }

                const prop = prospect[propertyName as keyof ProspectPreview] as StringArrayVisibility;
                if (prop) {
                    const currentList = prop.list;
                    if (currentList && currentList.length > 0) {
                        return currentList[0];
                    }
                }

                return '';
            },
            getDisplayName(prospect: ProspectPreview): string {
                return Utils.FormatUserNameForDisplay(prospect?.firstName, prospect?.lastName);
            },
            getNameClass(prospect: ProspectPreview): string {
                let chunks = this.getDisplayName(prospect).split(' ');
                let longestChunk = chunks.reduce((longest, current) => {
                    return current.length > longest.length ? current : longest;
                }, '');

                return 'prospect-name' + (longestChunk.length > 21 ? ' name-break' : '');
            },
            getHeightText(prospect: ProspectPreview): string {
                if (!prospect || !prospect.heightInInches || !prospect.heightInInches.value) {
                    logInvalidParams(this.$options.name, 'getHeightText');
                    return '';
                }

                const heightInInches = prospect.heightInInches.value;
                const feet = Math.floor(heightInInches / 12);
                const inches = heightInInches - feet * 12;
                return feet + "' " + inches + '"';
            },
        },
    });
