
    import Vue, { PropOptions } from 'vue';
    import mixins from 'vue-typed-mixins';
    import { ApiUtils } from '@/tsfiles/apiutils';
    import { Utils } from '@/tsfiles/utils';
    import { logInvalidParams } from '@/tsfiles/errorlog';
    import VueConstants from '@/components/VueConstants';
    import StarRating from '@/components/uiutils/StarRating.vue';
    import {
        DatingService,
        Match,
        SavePostDateSurveyRequest,
        PostDateSurveyDefinitions,
        SurveyListDefinition,
        SharedConstants,
    } from '@bostonventurestudio/lola-api';
    import { RoleUtils } from '@/tsfiles/roleutils';

    export default mixins(VueConstants).extend({
        name: 'PostDateSurvey',

        components: {
            'star-rating': StarRating,
        },

        props: {
            match: {
                type: Object,
                required: true,
            } as PropOptions<Match>,

            definitions: {
                type: Object,
                required: true,
            } as PropOptions<PostDateSurveyDefinitions>,
        },

        data() {
            return {
                submitting: false,
                reportUser: false,
                survey: {} as SavePostDateSurveyRequest,
                likeSelection: [] as any[],
                dislikeSelection: [] as any[],

                dateConfirmed: '',
                dateConfirmedOptions: [
                    { item: 'yes', name: 'Yes' },
                    { item: 'no', name: 'No' },
                ],

                dateAgain: '',
                dateAgainOptions: [
                    { item: 'yes', name: 'Yes' },
                    { item: 'no', name: 'No' },
                ],
                postDateReschedule: '',
                postDateRescheduleOptions: [
                    { item: 'yes', name: 'Yes' },
                    { item: 'no', name: 'No' },
                ],
            };
        },

        mounted() {
            Vue.set(this.survey, 'rating', 0);
        },

        computed: {
            disableSubmit(): boolean {
                if (this.dateConfirmed === '') {
                    return true;
                } else if (this.dateConfirmed === 'no') {
                    return (
                        this.survey.noDateReason === undefined ||
                        this.survey.noDateReason === '' ||
                        (this.isNoPlanMadeNoDateReason() && this.postDateReschedule === '')
                    );
                }

                // dateConfirmed is 'yes'

                if (this.dateAgain === '') {
                    return true;
                } else if (this.dateAgain === 'no') {
                    return this.survey.badDateReason === undefined || this.survey.badDateReason === '';
                } else {
                    const likeTagCount = this.getTagCount(this.likeSelection);
                    const dislikeTagCount = this.getTagCount(this.dislikeSelection);
                    if (likeTagCount === 0) {
                        return true;
                    }
                }

                return false;
            },
            hasAccess(): boolean {
                return (
                    this.$store.getters.isSignedIn &&
                    (!this.gettingRecordForAnotherUser || (this.gettingRecordForAnotherUser && RoleUtils.CanSupportUsers()))
                );
            },
            gettingRecordForAnotherUser(): boolean {
                return this.$route.params.userId != this.$store.getters.getAuthedUserId;
            },
            userId(): number {
                return parseInt(this.$route.params.userId == this.$store.getters.getAuthedUserId ? '' : this.$route.params.userId);
            },
        },

        methods: {
            async submitSurvey() {
                if (this.submitting) {
                    return;
                }

                if (!this.match) {
                    logInvalidParams(this.$options.name, 'submitSurvey');
                    return;
                }

                // Set up new survey with just the required fields based on submit type.
                const saveSurvey = {} as SavePostDateSurveyRequest;

                saveSurvey.dateConfirmed = this.dateConfirmed === 'yes';

                // Date did not happen?
                if (!saveSurvey.dateConfirmed) {
                    saveSurvey.noDateReason = this.survey.noDateReason;
                    if (this.survey.noDateReason === 'other') {
                        saveSurvey.noDateOther = this.survey.noDateOther;
                    }
                } else {
                    // Date happened
                    saveSurvey.rating = this.survey.rating;

                    // Bad date?
                    if (this.dateAgain === 'no') {
                        saveSurvey.badDateReason = this.survey.badDateReason;
                        if (this.survey.badDateReason === 'other') {
                            saveSurvey.badDateOther = this.survey.badDateOther;
                        } else if (this.survey.badDateReason === 'unsafe') {
                            saveSurvey.badDateUnsafe = this.survey.badDateUnsafe;
                        }
                    }

                    if (this.dateAgain === 'yes' || this.survey.badDateReason === 'bad-chemistry') {
                        // Store the likes and dislikes, if any
                        saveSurvey.likeTags = [] as string[];
                        saveSurvey.dislikeTags = [] as string[];

                        for (const [idx, tag] of this.definitions!.likeTags!.list!.entries()) {
                            if (this.likeSelection[idx]) {
                                saveSurvey.likeTags.push(tag.key as string);
                            }
                        }

                        for (const [idx, tag] of this.definitions!.dislikeTags!.list!.entries()) {
                            if (this.dislikeSelection[idx]) {
                                saveSurvey.dislikeTags.push(tag.key as string);
                            }
                        }
                    }
                }

                saveSurvey.matchId = this.match.matchId;
                saveSurvey.creatorUserId = this.userId || this.$store.getters.getAuthedUserId;
                saveSurvey.partnerUserId = this.match.partnerUserId;
                saveSurvey.dateAgain = this.dateAgain === 'yes';

                try {
                    this.submitting = true;
                    await ApiUtils.apiWrapper(DatingService.savePostDateSurvey, saveSurvey);

                    if (this.isNoPlanMadeNoDateReason() && this.postDateReschedule == 'yes') {
                        await this.reschedule();
                    }

                    // Reset survey
                    this.survey = {} as SavePostDateSurveyRequest;
                    Vue.set(this.survey, 'rating', 0);

                    this.reportUser = false;
                    this.likeSelection = [];
                    this.dislikeSelection = [];
                    this.dateConfirmed = '';
                    this.dateAgain = '';

                    this.submitting = false;
                    alert('Survey submitted!');
                    this.$emit('submitted');
                } catch (error: any) {
                    Utils.CommonErrorHandler(error);
                }
            },

            newRating(val: number) {
                Vue.set(this.survey, 'rating', val);
            },

            getTagCount(list: any[]): number {
                let count = 0;
                for (const item of list) {
                    if (item) {
                        count++;
                    }
                }

                return count;
            },

            selectCountStr(definitionType: string): string {
                if (!this.definitions) {
                    logInvalidParams(this.$options.name, 'selectCount');
                    return '';
                }

                const def = this.definitions[definitionType as keyof PostDateSurveyDefinitions] as SurveyListDefinition;

                let ret = '';
                if (def.minAllowed) {
                    ret = '(select ' + def.minAllowed.toString();
                }

                if (def.maxAllowed) {
                    if (ret) {
                        ret += ' to ' + def.maxAllowed.toString();
                    } else {
                        ret = '(up to ' + def.maxAllowed.toString();
                    }

                    ret += ')';
                }

                return ret;
            },

            tagCheckboxDisabled(idx: number, list: any[], definitionType: string): boolean {
                if (!this.definitions) {
                    logInvalidParams(this.$options.name, 'tagCheckboxDisabled');
                    return false;
                }

                // If already selected, leave enabled
                if (list[idx]) {
                    return false;
                }

                const count = this.getTagCount(list);
                const def = this.definitions[definitionType as keyof PostDateSurveyDefinitions] as SurveyListDefinition;
                return def.maxAllowed !== undefined && count >= def.maxAllowed;
            },
            isNoPlanMadeNoDateReason(): boolean {
                return this.dateConfirmed === 'no' && this.survey.noDateReason === SharedConstants.DATING_NO_DATE_REASON_NO_PLAN_MADE;
            },
            async reschedule() {
                if (!this.match || this.match.matchId === 0) {
                    logInvalidParams(this.$options.name, 'reschedule');
                    return;
                }

                try {
                    await ApiUtils.apiWrapper(DatingService.unmatch, {
                        matchId: this.match.matchId,
                        reason: SharedConstants.DATING_UNMATCH_REASON_RESCHEDULE,
                    });
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },
        },
    });
