
    import Vue from 'vue';
    import * as constants from '@/tsfiles/constants';
    import EventBus from '@/eventbus';
    import { Utils } from '@/tsfiles/utils';
    import { ApiUtils } from '@/tsfiles/apiutils';
    import { logInvalidParams } from '@/tsfiles/errorlog';
    import DatingCalendarRow from '@/components/dating/DatingCalendarRow.vue';
    import { SharedConstants, DatingService, GetCalendarResponse, SaveCalendarRequest, CalendarEntry } from '@bostonventurestudio/lola-api';

    export default Vue.extend({
        name: 'UserCalendar',

        components: {
            'dating-row': DatingCalendarRow,
        },

        props: {},

        data() {
            return {
                dates: [] as CalendarEntry[],
            };
        },

        watch: {},

        mounted() {
            EventBus.$on(SharedConstants.NOTIFICATION_DATE_UNMATCHED, this.fetchCalendar);
            EventBus.$on(SharedConstants.NOTIFICATION_DATE_RESCHEDULED, this.fetchCalendar);
            EventBus.$on(SharedConstants.NOTIFICATION_NEW_DATE_MATCH, this.fetchCalendar);

            this.fetchCalendar();
        },

        beforeDestroy() {
            EventBus.$off(SharedConstants.NOTIFICATION_DATE_UNMATCHED, this.fetchCalendar);
            EventBus.$off(SharedConstants.NOTIFICATION_DATE_RESCHEDULED, this.fetchCalendar);
            EventBus.$off(SharedConstants.NOTIFICATION_NEW_DATE_MATCH, this.fetchCalendar);
        },

        computed: {
            showViewProspectsButton(): boolean {
                for (const item of this.dates) {
                    if (item.available) {
                        return true;
                    }
                }

                return false;
            },
        },

        methods: {
            async fetchCalendar() {
                try {
                    const ret = (await ApiUtils.apiWrapper(DatingService.getCalendar)) as GetCalendarResponse;
                    if (ret && ret.entries && ret.entries.length > 0) {
                        Vue.set(this, 'dates', ret.entries);
                    } else {
                        this.dates = [] as CalendarEntry[];
                    }
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },

            rowSelected(idx: number) {
                if (idx < 0 || idx > this.dates.length) {
                    logInvalidParams(this.$options.name, 'rowSelected');
                    return;
                }

                const item = this.dates[idx];
                if (item.match) {
                    if (!item.match.matchId || !item.match.partnerUserId || item.match.partnerUserId <= 0) {
                        logInvalidParams(this.$options.name, 'rowSelected - invalid partnerUserId');
                        return;
                    }

                    const matchId = item.match.matchId.toString();
                    this.$router.push({
                        name: constants.ROUTE_USER_DATE,
                        query: { matchId },
                    });
                } else if (!item.readOnly) {
                    this.checkboxToggled(idx);
                }
            },

            async checkboxToggled(idx: number) {
                if (idx < 0 || idx >= this.dates.length) {
                    logInvalidParams(this.$options.name, 'checkboxToggled');
                    return;
                }

                try {
                    // Don't change this.dates[idx].available, then send in this.dates[idx],
                    // since the change will cause a re-render.  We would get 2 updates
                    // to the DatingCalendarRow component.
                    const ret = await ApiUtils.apiWrapper(DatingService.saveCalendar, {
                        entry: {
                            date: this.dates[idx].date,
                            available: !this.dates[idx].available, // Send the opposite
                            tonight: this.dates[idx].tonight,
                            tomorrow: this.dates[idx].tomorrow,
                        } as CalendarEntry,
                    } as SaveCalendarRequest);

                    // Replace with the response
                    if (ret.entry) {
                        this.dates.splice(idx, 1, Utils.deepCopy(ret.entry));
                    }
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },
        },
    });
