
    import Vue, { PropOptions, reactive } from 'vue';
    import { ApiUtils } from '@/tsfiles/apiutils';
    import { Utils } from '@/tsfiles/utils';
    import { logInvalidParams } from '@/tsfiles/errorlog';

    import { SharedConstants, AdminService } from '@bostonventurestudio/lola-api';
    import { AdminserviceUpdateUserPriority } from '@bostonventurestudio/lola-api/dist/models/AdminserviceUpdateUserPriority';

    export default Vue.extend({
        name: 'UserPriority',

        components: {},

        props: {
            id: {
                type: Number,
                required: true,
            } as PropOptions<Number>,
            priority: {
                type: String,
                required: true,
            } as PropOptions<string>,
            spin: {
                type: Boolean,
                required: false,
                default: false,
            },
            readonly: {
                type: Boolean,
                required: false,
                default: false,
            },
        },

        data() {
            return {
                priorityStatus: SharedConstants.USER_PROFILE_PRIORITY_NONE,
                updatingUserPriority: false,
                priorityOptions: [
                    { key: SharedConstants.USER_PROFILE_PRIORITY_NONE, text: 'None' },
                    { key: SharedConstants.USER_PROFILE_PRIORITY_LOW, text: 'Low' },
                    { key: SharedConstants.USER_PROFILE_PRIORITY_MEDIUM, text: 'Medium' },
                    { key: SharedConstants.USER_PROFILE_PRIORITY_HIGH, text: 'High' },
                ],
            };
        },

        watch: {
            priority: {
                immediate: true,
                handler(newPriority) {
                    this.priorityStatus = newPriority || SharedConstants.USER_PROFILE_PRIORITY_NONE;
                },
            },
        },

        methods: {
            async updateUserPriority(priorityStatus: string) {
                if (this.readonly) return;
                else if (!this.id || priorityStatus == '') {
                    logInvalidParams(this.$options.name, 'updateUserPriority');
                    return;
                }
                try {
                    this.updatingUserPriority = true;
                    this.priorityStatus = priorityStatus;
                    var post = { userId: this.id, priority: priorityStatus } as AdminserviceUpdateUserPriority;
                    await ApiUtils.apiWrapper(AdminService.updateUserPriority, post);
                    this.$emit('priority-changed');
                    setTimeout(() => {
                        this.updatingUserPriority = false;
                    }, 1000); // 1000 milliseconds = 1 seconds
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },
            isPressed(key: string) {
                return this.priorityStatus === key;
            },
            showSpinner(key: string) {
                return this.spin && this.updatingUserPriority && this.isPressed(key);
            },
        },
    });
