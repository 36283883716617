
    import Vue, { PropOptions } from 'vue';
    import mixins from 'vue-typed-mixins';
    import { Utils } from '@/tsfiles/utils';
    import { ApiUtils } from '@/tsfiles/apiutils';
    import * as constants from '@/tsfiles/constants';
    import { RoleUtils } from '@/tsfiles/roleutils';
    import * as analytics from '@/tsfiles/analytics';
    import { logInvalidParams, logInvalidResponse } from '@/tsfiles/errorlog';
    import { PageNavigationData } from '@/tsfiles/interfaces';
    import VueConstants from '@/components/VueConstants';
    import Avatar from '@/components/Avatar.vue';
    import FollowButton from '@/components/uiutils/FollowButton.vue';
    import SocialMediaLinks from '@/components/user/SocialMediaLinks.vue';
    import OffensiveReportModal from '@/generic-modals/OffensiveReportModal.vue';
    import VerifiedBadge from '@/generic-modals/VerifiedBadge.vue'; // Not used
    import VueGallerySlideshow from 'vue-gallery-slideshow';
    import {
        SharedConstants,
        UserService,
        PublicUser,
        StringArrayVisibility,
        Profile,
        DatingService,
        SwipeRequest,
        CreateSharedProfileResponse,
    } from '@bostonventurestudio/lola-api';
    import { UserserviceProfile } from '@bostonventurestudio/lola-api/dist/models/UserserviceProfile';

    Vue.use(VueGallerySlideshow);

    export default mixins(VueConstants).extend({
        name: 'UserProfile',

        components: {
            'url-avatar': Avatar,
            'follow-button': FollowButton,
            'social-media-links': SocialMediaLinks,
            'offensive-report-modal': OffensiveReportModal,
            'verified-badge': VerifiedBadge, // Not used
            VueGallerySlideshow,
        },

        props: {
            embeddedUserId: {
                type: Number,
                required: false,
                default: 0,
            },

            saveDS: {
                type: Boolean,
                required: false,
                default: false,
            },

            viaSupportPage: {
                type: Boolean,
                required: false,
                default: false,
            },

            // Provided by parent if part of a date or past-date
            matchId: {
                type: Number,
                required: false,
                default: 0,
            },

            eventBus: {
                type: Vue,
                required: false,
                default: null,
            },
        },

        data() {
            return {
                userId: 0,
                user: undefined as PublicUser | undefined,
                isMe: false,
                currentTab: 0,
                showFlagUserModal: false,

                totalLists: 0,
                totalRecommendations: 0,

                currentNavigation: { page: constants.ROUTE_USER_HOME } as PageNavigationData,
                blockerUserId: 0,

                photoUrls: [] as { url: string; alt: string }[],
                photoIndex: null as any,

                alertMessage: null as string | null,
                dismissCountDown: 0,
                permanent: false,
                alertType: 'success',
                alertLink: null as string | null,
            };
        },

        watch: {
            '$route.params.userId': {
                immediate: true,
                handler(newVal, oldVal) {
                    if (this.embeddedUserId === 0 && newVal && newVal !== oldVal) {
                        this.userId = newVal;
                        this.fetchUser(newVal);

                        //
                        // If page navigation contains data, process.
                        //
                        const nav = this.$store.getters.getPageNavigation(constants.ROUTE_USER_HOME);
                        if (nav && nav.tab) {
                            this.$nextTick(() => {
                                this.currentTab = nav.tab;
                                this.currentNavigation.tab = nav.tab;
                            });
                        }
                    }
                },
            },

            embeddedUserId: {
                immediate: true,
                deep: true,
                handler(newVal: number, oldVal: number) {
                    if (newVal && newVal !== oldVal) {
                        this.fetchUser(newVal);
                    }
                },
            },

            photoIndex(newIndex) {
                if (newIndex !== null) {
                    if (this.isImageVerified(newIndex)) {
                        this.insertVerifiedBadge(newIndex);
                    }
                }
            },
        },

        beforeDestroy() {
            this.$store.commit('setPageNavigation', this.currentNavigation);
        },

        computed: {
            isSupportUser(): boolean {
                return this.$store.getters.isSignedIn && RoleUtils.CanSupportUsers();
            },
            // Return the user's name.  Combined with lastname if given.
            getDisplayName(): string {
                return Utils.FormatUserNameForDisplay(this.user?.firstName, this.user?.lastName);
            },

            getHeightText(): string {
                if (!this.user || !this.user.profile || !this.user.profile.heightInInches || !this.user.profile.heightInInches.value) {
                    logInvalidParams(this.$options.name, 'getHeightText');
                    return '';
                }

                const heightInInches = this.user.profile.heightInInches.value;
                const feet = Math.floor(heightInInches / 12);
                const inches = heightInInches - feet * 12;
                return feet + "' " + inches + '"';
            },

            sexualOrientationValue(): string {
                if (
                    this.user &&
                    this.user.profile &&
                    this.user.profile.sexualOrientation &&
                    this.user.profile.sexualOrientation.list &&
                    this.user.profile.sexualOrientation.list.length > 0
                ) {
                    return this.user.profile.sexualOrientation.list[0];
                }

                return '';
            },
            isSupportAdmin() {
                return RoleUtils.CanSupportUsers();
            },
        },

        methods: {
            showField(property: any): boolean {
                if (!property) return false;
                else if (this.isSupportUser) return true;

                var obj = property ?? { publiclyVisible: true };
                return obj && obj.hasOwnProperty('publiclyVisible') ? obj.publiclyVisible : true;
            },
            hasList(propertyName: string): boolean {
                if (!this.user || !this.user.profile) {
                    logInvalidParams(this.$options.name, 'hasList');
                    return false;
                }

                const profile = this.user.profile as Profile;
                const prop = profile[propertyName as keyof Profile] as StringArrayVisibility;
                if (prop) {
                    const currentList = prop.list;
                    return currentList !== undefined && currentList.length > 0;
                }

                return false;
            },

            getSeparatedList(propertyName: string, separator: string): string {
                if (!this.user || !this.user.profile) {
                    logInvalidParams(this.$options.name, 'getCommaSeparatedList');
                    return '';
                }

                const profile = this.user.profile as Profile;
                const prop = profile[propertyName as keyof Profile] as StringArrayVisibility;
                if (prop) {
                    const currentList = prop.list;
                    if (currentList && currentList.length > 0) {
                        return currentList.join(separator);
                    }
                }

                return '';
            },

            getFirstItemInList(propertyName: string): string {
                if (!this.user || !this.user.profile) {
                    logInvalidParams(this.$options.name, 'getFirstItemInList');
                    return '';
                }

                const profile = this.user.profile as Profile;
                const prop = profile[propertyName as keyof Profile] as StringArrayVisibility;
                if (prop) {
                    const currentList = prop.list;
                    if (currentList && currentList.length > 0) {
                        return currentList[0];
                    }
                }

                return '';
            },

            async fetchUser(userId: number) {
                try {
                    this.blockerUserId = 0;

                    this.user = await ApiUtils.apiWrapper(UserService.getPublicUserById, userId);
                    if (!this.user || !this.user.userId) {
                        logInvalidResponse(this.$options.name, 'fetchUser');
                        return;
                    }

                    this.isMe = this.$store.getters.isAuthedUserId(this.user.userId);

                    //
                    // Put photo urls and creation dates into the local array
                    //
                    this.photoUrls = [] as { url: string; alt: string }[];
                    if (this.user.profile?.mediaList && this.user.profile.mediaList.length > 0) {
                        for (const item of this.user.profile.mediaList) {
                            // Check if item is verified; if not, set created to ''
                            const altText = item.verified ? (this.formatDate(item.created) as string) : '';
                            this.photoUrls.push({ url: item.url as string, alt: altText });
                        }
                    }

                    //
                    // If the parent asked us to save this userId as the DS in adChannel,
                    // make the change.  Overwrite DS, but leave everything else the same.  We
                    // tried ignoring internal celebrities, but since Paul English is marked
                    // as a celebrity, we couldn't add this check to the 'if'.
                    //
                    // NOTE: If there is a ds query parameter, which will set the downloadSource
                    // in router.ts afterEach, this will overwrite that value.  For example:
                    //    https://m.lola.com/@amy?ds=tiktok&cs=video&aid=92
                    // router.ts will set downloadSource to tiktok, but this code will then
                    // reset to amy's user Id. After discussion with Sam, we said that's ok for now, since
                    // there are no plans for ad buys that go to a specific user's profile.
                    //
                    if (!this.isMe && this.saveDS) {
                        const adChannel = this.$store.getters.getAdChannel;
                        adChannel.downloadSource = this.user.userId?.toString();
                        this.$store.commit('setAdChannel', adChannel);
                    }
                } catch (error: any) {
                    if (error.response.status === 409 && error.response.data.message === SharedConstants.WARNING_RESOURCE_BLOCKED) {
                        this.blockerUserId = userId;
                        return;
                    }

                    Utils.CommonErrorHandler(error);
                }
            },

            openSettings() {
                this.$router.push({ name: constants.ROUTE_USER_SETTINGS });
            },

            async reportAbuse(message: string) {
                if (!this.user || !this.user.userId) {
                    logInvalidParams(this.$options.name, 'reportAbuse');
                    return;
                }

                try {
                    await ApiUtils.apiWrapper(UserService.flagUser, {
                        matchId: this.matchId,
                        userId: this.user.userId,
                        reason: message,
                    });

                    Vue.set(this.user, 'flaggedByAuthedUser', true);

                    analytics.logAppInteraction(analytics.ANALYTICS_ACTION_FLAG_PROFILE, this.user.userId.toString());
                    alert('This user has been reported as potentially abusing the platform');
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },

            async blockUser() {
                if (!this.user || !this.user.userId) {
                    logInvalidParams(this.$options.name, 'blockUser');
                    return;
                }

                try {
                    await ApiUtils.apiWrapper(UserService.blockUser, { userId: this.user.userId });

                    analytics.logAppInteraction(analytics.ANALYTICS_ACTION_BLOCK_USER, this.user.userId.toString());
                    alert('This user has been block and you will no longer see them');

                    //
                    // Go to the user's calendar.  We don't want to do this.$router.go(-1),
                    // since the previous page may have been the blocked user's list contents, etc.
                    //
                    this.$router.replace({ name: constants.ROUTE_USER_CALENDAR });
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },

            async likeUser() {
                if (!this.user || !this.user.userId) {
                    logInvalidParams(this.$options.name, 'likeUser');
                    return;
                }

                try {
                    const res = await ApiUtils.apiWrapper(DatingService.swipe, {
                        affinity: true,
                        userId: this.user.userId,
                    } as SwipeRequest);

                    // If the swipe results in a match, go to the date page.
                    if (res && res.match && res.match.matchId) {
                        this.$router.push({
                            name: constants.ROUTE_USER_DATE,
                            query: { matchId: res.match.matchId.toString() },
                        });
                    }
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },

            // Force a match with this user.
            async forceMatch() {
                if (!this.user || !this.user.userId) {
                    logInvalidParams(this.$options.name, 'blockUser');
                    return;
                }
                try {
                    const ret = await ApiUtils.apiWrapper(DatingService.forceMatch, {
                        prospectUserId: this.user.userId,
                    });

                    if (ret && ret.match) {
                        const matchId = ret.match.matchId ? ret.match.matchId.toString() : '0';
                        this.$router.push({
                            name: constants.ROUTE_USER_DATE,
                            query: { matchId },
                        });
                    } else {
                        alert('Unable to match.  The user might already be matched, you have no availability, or the force match failed.');
                    }
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },

            showAlert(message: string, link: string, alertType: string, perm: boolean) {
                this.permanent = perm;
                if (!perm) {
                    this.dismissCountDown = 5;
                }

                this.alertMessage = message;
                this.alertType = alertType;
                this.alertLink = link;
            },

            alertCountDownChanged(dismissCountDown: number) {
                this.dismissCountDown = dismissCountDown;
            },

            async shareProfile() {
                if (!this.user || !this.user.userId) {
                    logInvalidParams(this.$options.name, 'shareProfile');
                    return;
                }
                try {
                    const ret = (await ApiUtils.apiWrapper(UserService.shareProfile, {
                        prospectId: this.user.userId,
                    })) as CreateSharedProfileResponse;
                    if (ret && ret.url) {
                        this.showAlert('Profile shared. Link copied to clipboard.', ret.url, 'success', false);
                        navigator.clipboard.writeText(ret.url);
                    } else {
                        this.showAlert('Unable to share profile.', '', 'danger', false);
                    }
                } catch (error: any) {
                    if (error.response.status === 429 && error.response.data.message === 'Share limit reached') {
                        this.showAlert('Share limit reached', '', 'danger', false);
                    }
                    Utils.CommonErrorHandler(error);
                }
            },

            isImage(item: any): boolean {
                return item && item.type === SharedConstants.MEDIA_TYPE_PHOTO;
            },

            isImageVerified(photoIndex: number): boolean {
                if (!this.user || !this.user.profile || !this.user.profile.mediaList) {
                    logInvalidParams(this.$options.name, 'isImageVerified');
                    return false;
                }
                const item = this.user.profile?.mediaList?.[photoIndex] ?? null;
                return item && item.type === SharedConstants.MEDIA_TYPE_PHOTO && item.verified === true;
            },

            isVideo(item: any): boolean {
                return item && item.type === SharedConstants.MEDIA_TYPE_VIDEO;
            },

            reloadUser() {
                if (!this.user || !this.user.userId) {
                    logInvalidParams(this.$options.name, 'reloadUser');
                    return;
                }

                this.fetchUser(this.user.userId);
            },

            insertVerifiedBadge(photoIndex: number) {
                if (!this.user || !this.user.profile || !this.user.profile.mediaList) {
                    logInvalidParams(this.$options.name, 'isImageVerified');
                    return false;
                }
                const item = this.user.profile?.mediaList?.[photoIndex] ?? null;

                this.$nextTick(() => {
                    const container = document.querySelector('.vgs__container');
                    // set overflow to visible to make sure the badge is visible
                    if (container) {
                        const container = document.querySelector('.vgs__container') as HTMLElement;
                        container.style.overflow = 'visible';
                    }
                    if (container && !container.querySelector('.verified-badge-container')) {
                        // Create and insert the verified badge component
                        // Would have loved to use Vue to create a VerifiedBadge but could not generate it dynamically
                        const verifiedBadgeContainer = document.createElement('div');
                        verifiedBadgeContainer.className = 'verified-badge-container';
                        const badge = document.createElement('div');
                        badge.className = 'verified-badge';
                        badge.textContent = 'Taken ' + this.formatDate(item.created);
                        const i = document.createElement('i');
                        i.className = 'fa-solid fa-badge-check';
                        badge.appendChild(i);
                        verifiedBadgeContainer.appendChild(badge);
                        container.appendChild(verifiedBadgeContainer);
                    }
                });
            },

            disableVerifiedBadge() {
                const container = document.querySelector('.vgs__container');
                if (container) {
                    const container = document.querySelector('.vgs__container') as HTMLElement;
                    container.style.overflow = 'hidden';
                }
            },

            formatDate(date: Date | undefined) {
                if (!date) return '';
                const d = new Date(date);
                let month = '' + (d.getMonth() + 1);
                let day = '' + d.getDate();
                let year = d.getFullYear();

                if (month.length < 2) month = '0' + month;
                if (day.length < 2) day = '0' + day;

                return [month, day, year.toString().substring(-2)].join('/');
            },
        },

        mounted() {
            if (this.eventBus) {
                this.eventBus.$on('reload-user', this.reloadUser);
            }
        },
    });
